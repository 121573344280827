<template>
  <div class="browse-records-bg">
    <div class="browse-records-con">
      <b-container fluid>
        <h2 class="page-title">浏览记录</h2>
        <div class="browse-records-block">
          <el-timeline v-for="(item,index) in browseItems" :key="index">
            <el-timeline-item :timestamp="index" placement="top">
              <div class="block-item">
                <b-row class="b-row">
                  <b-col class="b-col" sm="6" md="4" lg="3" xl="2" v-for="(it, idx) in item.data" :key="idx">
                    <!-- it.type == 3 三维模型 -->
                    <el-card shadow="hover" v-if="it.type == 3">
                      <div class="item-wrap" @click="to3DModel(it.work)">
                        <div class="pic">
                          <img :src="it.work.img" :alt="it.work.modelName">
                        </div>
                        <div class="detail">
                          <div class="item-name">{{it.work.modelName}}</div>
                          <div class="item-info">ID:{{it.work.modelId}} </div>
                        </div>
                      </div>
                    </el-card>
                    <!-- it.type == 1 二维方案 -->
                    <el-card shadow="hover" v-if="it.type == 1">
                      <div class="item-wrap" @click="to2DDetail(it.work)">
                        <div class="pic">
                          <img :src="it.work.coverUri" :alt="it.work.name">
                        </div>
                        <div class="detail">
                          <div class="item-name">{{it.work.name}}</div>
                          <div class="item-info">
                            <div class="designer-info">
                              <img class="user-avatar" :src="it.work.headImgUri || require('@/../public/img/person.431c96c3.png')" />
                              <div class="solution-designer-name ehome-solution-name">{{$t('portalTitle')}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                    <!-- it.type == 2 三维方案 -->
                    <el-card shadow="hover" v-if="it.type == 2">
                      <div class="item-wrap" @click="to3DDetail(it.work)">
                        <div class="pic">
                          <img :src="it.work.imgPath" :alt="it.work.worksName">
                        </div>
                        <div class="detail">
                          <div class="item-name">{{it.work.worksName}}</div>
                          <div class="item-info">{{it.work.hourseType}} | {{it.work.styleName}} | {{it.work.measureArea}} </div>
                        </div>
                      </div>
                    </el-card>
                    <!-- it.type == 4 商品 -->
                    <el-card shadow="hover" v-if="it.type == 4">
                      <div class="item-wrap" @click="toProductDetail(it.work)">
                        <div class="pic">
                          <img :src="it.work.goodsImg" :alt="it.work.goodsName">
                        </div>
                        <div class="detail">
                          <div class="item-name">{{it.work.goodsName}}</div>
                          <div class="price-wrap">
                            <span>¥{{it.work.distributionPrice}}</span>
                            <span>¥{{it.work.salePrice}}</span>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </b-col>
                </b-row>
              </div>
            </el-timeline-item>
            <!-- <el-timeline-item timestamp="2021/9/7" placement="top">
              <div class="block-item">
                <b-row class="b-row">
                  <b-col class="b-col" sm="6" md="4" lg="3" xl="2">
                    <el-card shadow="hover">
                      <div class="item-wrap">
                        <div class="pic">
                          <img src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/7151541f-d037-45c7-844e-5750cc01bb18.jpg" alt="">
                        </div>
                        <div class="detail">
                          <div class="item-name">中海玄武公馆中海玄武公馆中海玄武公馆中海玄武公馆</div>
                          <div class="item-info">两房两厅 | 轻奢 | 127㎡ </div>
                        </div>
                      </div>
                    </el-card>
                  </b-col>
                  <b-col class="b-col" sm="6" md="4" lg="3" xl="2">
                    <el-card shadow="hover">
                      <div class="item-wrap">
                        <div class="pic">
                          <img src="https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/3d68063d-4760-4c92-9d00-3d9db7222c28.png" alt="">
                        </div>
                        <div class="detail">
                          <div class="item-name">现代简约客厅</div>
                          <div class="item-info">
                            <div class="designer-info">
                              <img class="user-avatar" src="@/../public/img/person.431c96c3.png" />
                              <div class="solution-designer-name ehome-solution-name">{{$t('portalTitle')}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </b-col>
                  <b-col class="b-col" sm="6" md="4" lg="3" xl="2">
                    <el-card shadow="hover">
                      <div class="item-wrap">
                        <div class="pic">
                          <img src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/db2a24d0-bdd6-4505-9e6c-d7a1f8ab455d.png" alt="">
                        </div>
                        <div class="detail">
                          <div class="item-name">ZD-LPLN-L-SF026-2</div>
                          <div class="item-info">ID:38 </div>
                        </div>
                      </div>
                    </el-card>
                  </b-col>
                </b-row>
              </div>
            </el-timeline-item>
            <el-timeline-item timestamp="2021/9/6" placement="top">
              <div class="block-item">
                <b-row class="b-row">
                  <b-col class="b-col" sm="6" md="4" lg="3" xl="2">
                    <el-card shadow="hover">
                      <div class="item-wrap">
                        <div class="pic">
                          <img src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/7151541f-d037-45c7-844e-5750cc01bb18.jpg" alt="">
                        </div>
                        <div class="detail">
                          <div class="item-name">中海玄武公馆中海玄武公馆中海玄武公馆中海玄武公馆</div>
                          <div class="item-info">两房两厅 | 轻奢 | 127㎡ </div>
                        </div>
                      </div>
                    </el-card>
                  </b-col>
                  <b-col class="b-col" sm="6" md="4" lg="3" xl="2">
                    <el-card shadow="hover">
                      <div class="item-wrap">
                        <div class="pic">
                          <img src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/42de5b92-6c10-44fa-8bb0-21cc61eb5696.png" alt="">
                        </div>
                        <div class="detail">
                          <div class="item-name">现代简约客厅沙发家用4人位真皮沙发 休闲办公接待功能沙发</div>
                          <div class="item-info">￥20349.00</div>
                        </div>
                      </div>
                    </el-card>
                  </b-col>
                  <b-col class="b-col" sm="6" md="4" lg="3" xl="2">
                    <el-card shadow="hover">
                      <div class="item-wrap">
                        <div class="pic">
                          <img src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/7151541f-d037-45c7-844e-5750cc01bb18.jpg" alt="">
                        </div>
                        <div class="detail">
                          <div class="item-name">中海玄武公馆中海玄武公馆中海玄武公馆中海玄武公馆</div>
                          <div class="item-info">两房两厅 | 轻奢 | 127㎡ </div>
                        </div>
                      </div>
                    </el-card>
                  </b-col>
                </b-row>
              </div>
            </el-timeline-item> -->
          </el-timeline>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
  import dump from "@/util/dump.js";
  import url from "@/api/config";
  export default {
    data(){
      return {
        token: null,
        browseItems: [],
        
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getBrowseRecords();
      },
      // 获取浏览记录
      getBrowseRecords(){
        this.$api.browseRecords({
          // 0全部 1天 2周 3月
          type: 0,
          pageNum: 1,
          pageSize: 10000,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.browseItems = res.data.list;
          }
        }).catch(err => console.error(err))
      },
      // 跳转到三维模型
      to3DModel(item){
        item.type = 3
        item.link = "/model3Ddetail";
        item.params = {
          id: item.modelId
        }
        dump.link(item);
      },
      // 跳转到二维方案
      to2DDetail(item) {
        item.type = 3;
        item.link = "/twodimensionalprogramme";
        item.params = {
          id: item.id
        };
        dump.link(item);
      },
      // 跳转到三维方案
      to3DDetail(item) {
        item.type = 2;
        // item.link = item.threeDUrl;
        item.link =
          `${url}/mapp/plan/getThreeDimensional?url=${url}&workId=${item.worksId}&token=${this.token}`;
        dump.link(item);
      },
      // 商品详情
      toProductDetail(obj) {
        dump.link({
          type: 3,
          link: "/product/details",
          params: {
            good_id: obj.goodsId,
          },
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/browseRecords/browseRecords";
</style>
